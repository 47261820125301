'use strict';

(function ($, window, document) {

  $(function () {


    // IE parallax scroll fix
    (function () {

      if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
        $('body').on('mousewheel', function () {
          // remove default behavior
          event.preventDefault();

          //scroll without smoothing
          var wheelDelta = event.wheelDelta;
          var currentScrollPosition = window.pageYOffset;
          window.scrollTo(0, currentScrollPosition - wheelDelta);
        });
      }

    })();


    // Animations
    (function () {

      const animations = {

        el: {
          animatedBlock: '.js_animated',
          animatedBlockSection: '.js_animated-section',
          $animatedBlockSections: $('.js_animated-section'),

          parallaxTop1: '.js_parallaxTop1',
          parallaxTop2: '.js_parallaxTop2',
          parallaxInner: '.js_parallax-inner',

          parallaxImages: '.js_parallaxImages',
          parallaxImage1: '.js_parallaxImage1',
          parallaxImage2: '.js_parallaxImage2',
          parallaxImage3: '.js_parallaxImage3',
          parallaxImage4: '.js_parallaxImage4',
          parallaxImage5: '.js_parallaxImage5',
          parallaxImage6: '.js_parallaxImage6',
          parallaxImage7: '.js_parallaxImage7',

          parallaxSection1: '.js_parallaxSection1',
          parallaxSection2: '.js_parallaxSection2',
          parallaxSection3: '.js_parallaxSection3'
        },

        config: {
        },

        init() {
          const windowWidth = $(window).width();

          // Parallax sections
          this.parallaxTop();

          // Text animations
          this.animateText();

          // Parallax images
          this.parallaxImages();

          // Parallax sections
          this.parallaxSections();
        },

        animateText() {
          const _this = this;

          var addAnimation = function (blockSectionNum) {
            // init controller
            const controller = new ScrollMagic.Controller();

            // build scene
            const scene = new ScrollMagic.Scene({
              triggerElement: `${_this.el.animatedBlockSection}${blockSectionNum}`,
              reverse: false,
              offset: -170
            })
            .setClassToggle(`${_this.el.animatedBlockSection}${blockSectionNum} ${_this.el.animatedBlock}`, 'c-animated--active')
            //.addIndicators({ name: blockSectionNum }) // add indicators (requires plugin)
            .addTo(controller);
          };

          const numAnimatedSections = _this.el.$animatedBlockSections.length;

          // Set scene for each of the animated text areas
          for (let i = 0; i < numAnimatedSections; i++) {
            addAnimation(i + 1);
          }
        },

        parallaxTop() {
          const _this = this;

          const windowWidth = $(window).width();

          if (windowWidth < 768) {
            var duration1 = '250%';
            var duration2 = '250%';
            var speed1 = '20%';
            var speed2 = '30%';
          } else {
            var duration1 = '200%';
            var duration2 = '400%';
            var speed1 = '30%';
            var speed2 = '30%';
          }

          // init controllers
          const controller1 = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 'onEnter', duration: duration1 } });
          const controller2 = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 'onEnter', duration: duration2 } });

          // build scenes
          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxTop1 })
            .setTween(`${_this.el.parallaxTop1} ${_this.el.parallaxInner}`, { y: speed1, ease: Linear.easeNone })
            .addTo(controller1);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxTop2 })
            .setTween(`${_this.el.parallaxTop2} ${_this.el.parallaxInner}`, { y: speed2, ease: Linear.easeNone })
            .addTo(controller2);
        },

        parallaxImages() {
          const _this = this;

          // init controller
          const controller = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 'onEnter', duration: '200%' } });

          // build scenes
          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage1 })
            .setTween(_this.el.parallaxImage1, { y: '-80%', ease: Linear.easeNone })
            .addTo(controller);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage2 })
            .setTween(_this.el.parallaxImage2, { y: '-30%', ease: Linear.easeNone })
            .addTo(controller);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage3 })
            .setTween(_this.el.parallaxImage3, { y: '-140%', ease: Linear.easeNone })
            .addTo(controller);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage4 })
            .setTween(_this.el.parallaxImage4, { y: '-110%', ease: Linear.easeNone })
            .addTo(controller);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage5 })
            .setTween(_this.el.parallaxImage5, { y: '-80%', ease: Linear.easeNone })
            .addTo(controller);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage6 })
            .setTween(_this.el.parallaxImage6, { y: '-50%', ease: Linear.easeNone })
            .addTo(controller);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxImage7 })
            .setTween(_this.el.parallaxImage7, { y: '-150%', ease: Linear.easeNone })
            .addTo(controller);
        },

        parallaxSections() {
          const _this = this;

          const windowWidth = $(window).width();

          if (windowWidth < 768) {
            var duration1 = '220%';
            var duration2 = '400%';
            var duration3 = '200%';
            var speed1 = '-40%';
            var speed2 = '-20%';
            var speed3 = '-15%';
          } else {
            var duration1 = '250%';
            var duration2 = '250%';
            var duration3 = '250%';
            var speed1 = '-100%';
            var speed2 = '-30%';
            var speed3 = '-80%';
          }

          // init controller
          const controller1 = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 'onEnter', duration: duration1 } });
          const controller2 = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 'onEnter', duration: duration2 } });
          const controller3 = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 'onEnter', duration: duration3 } });

          // build scenes
          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxSection1 })
            .setTween(_this.el.parallaxSection1, { y: speed1, ease: Linear.easeNone })
            .addTo(controller1);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxSection2 })
            .setTween(_this.el.parallaxSection2, { y: speed2, ease: Linear.easeNone })
            .addTo(controller2);

          new ScrollMagic.Scene({ triggerElement: _this.el.parallaxSection3 })
            .setTween(_this.el.parallaxSection3, { y: speed3, ease: Linear.easeNone })
            .addTo(controller3);

        }

      };

      animations.init();

    })();


  });

})(window.jQuery, window, document);
